<template>
  <div>
    <div class="ser-form">
      <Form :model="search">
        <div class="ser-form-item">
          <div class="form-item-label">搜索</div>
          <div class="form-item-content">
            <Input v-model="search.imei_code" placeholder="商户编号"></Input>
            <Input v-model="search.uname" placeholder="设备名称"></Input>
            <Button icon="md-search" type="primary" @click="searchInt">搜索</Button>
          </div>
        </div>
        <div class="ser-form-item">
          <div class="form-item-label">时间</div>
          <div class="form-item-content">
            <DatePicker v-model="search.start_date" transfer placement="bottom-end" type="datetime"
                        format="yyyy-MM-dd HH:mm" placeholder="绑定开始时间" style="width: 180px"></DatePicker>
            ~&nbsp;
            <DatePicker v-model="search.end_date" transfer placement="bottom-end" type="datetime"
                        format="yyyy-MM-dd HH:mm" placeholder="绑定结束时间" style="width: 180px"></DatePicker>
          </div>
        </div>
        <div class="ser-form-item">
          <div class="form-item-label">状态</div>
          <div class="form-item-content">
            <RadioGroup type="button" button-style="solid" v-model="search.status">
              <Radio :label="0">全部</Radio>
              <Radio :label="1">已绑定</Radio>
              <Radio :label="2">已解绑</Radio>
            </RadioGroup>
          </div>
        </div>
        <!--        <div class="ser-form-item">-->
        <!--          <div class="form-item-label">操作</div>-->
        <!--          <div class="form-item-content">-->
        <!--            <Button type="primary" icon="md-add" @click="model=true">新增设备</Button>-->
        <!--          </div>-->
        <!--        </div>-->
      </Form>
    </div>
    <div class="table-list">
      <Table :loading="loading" :columns="columns" :data="data">
        <template slot-scope="{row}" slot="action">
          <Button @click="edit(row)">位置信息</Button>
        </template>
      </Table>
    </div>
    <div class="ser-page">
      <Page class-name="page-r" :total="total" :page-size="search.pageSize" :page-size-opts="[15,30,50]" show-sizer
            show-total @on-change="changePage" @on-page-size-change="changePageSize"></Page>
    </div>
    <Modal v-model="model" title="新增设备">
      <div class="ser-form">
        <Form :mode="formData" :label-width="80">
          <FormItem label="设备名称">
            <Input v-model="formData.name"></Input>
          </FormItem>
          <FormItem label="分类">
            <Select v-model="formData.cate_id">
              <Option :value="0">请选择分类</Option>
              <Option :value="1">分类111</Option>
              <Option :value="2">分类222</Option>
            </Select>
          </FormItem>
          <FormItem label="品牌">
            <Select v-model="formData.type_id">
              <Option :value="0">请选择品牌</Option>
              <Option :value="1">分类111</Option>
              <Option :value="2">分类222</Option>
            </Select>
          </FormItem>
          <FormItem label="型号">
            <Select v-model="formData.type_id">
              <Option :value="0">请选择型号</Option>
              <Option :value="1">分类111</Option>
              <Option :value="2">分类222</Option>
            </Select>
          </FormItem>
          <FormItem label="状态">
            <RadioGroup v-model="formData.status">
              <Radio :label="1">显示</Radio>
              <Radio :label="0">不显示</Radio>
            </RadioGroup>
          </FormItem>
        </Form>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "Watch",
  data() {
    return {
      loading: false,
      model: false,
      search: {
        imei_code: '',
        uname: '',
        start_date: '',
        end_date: '',
        status: 0,
        page: 1,
        pageSize: 15
      },
      formData: {
        type_id: '',
        cate_id: '',
        brand_id: '',
        device_id: '',
        name: '',
        status: 1
      },
      columns: [
        {title: 'ID', type: 'index', width: 60, align: 'center'},
        {title: '设备号', key: 'device_id', minWidth: 80},
        {title: '商户号', key: 'store_code', minWidth: 80},
        {title: '设备名称', key: 'title', minWidth: 160, tooltip: true},
        {title: 'UID', key: 'uid', minWidth: 80},
        {
          title: '状态', key: 'status', minWidth: 100, render: (h, params) => {
            return h('div', [h('Tag', {props: {color: params.row.status ? 'success' : 'default'}}, params.row.status ? '已绑定' : '未绑定')])
          }
        },
        {title: '设备分类', key: 'device_id', minWidth: 80,render:(h,params)=>{return h('span',this.cate[params.row.cate_id])}},
        {title: '绑定时间', key: 'add_time', minWidth: 150},
      ],
      data: [],
      total: 0,
      cate:[]
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      var _this = this;
      this.loading = true;
      this.requestApi('/adm/get_cloud_device', {data: this.search}).then(function (res) {
        if (res.status) {
          _this.cate = res.cate;
          _this.data = res.data.data;
          _this.total = res.data.total;
        }
        _this.$nextTick(function () {
          _this.loading = false;
        })
      })
    },
    edit(item) {
      console.log(item)
    },
    searchInt() {
      this.search.page = 1;
      this.init();
    },
    changePage(page) {
      this.search.page = page;
      this.init();
    },
    changePageSize(size) {
      this.search.page = 1;
      this.search.pageSize = size;
      this.init();
    }
  }
}
</script>

<style scoped>
.ser-form {
  padding: 15px 15px 7px 15px;
  background-color: #FFFFFF;
  margin-bottom: 12px;
}

.ser-form .ser-form-item {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 8px;
  line-height: 32px;
  font-size: 12px;
}

.ser-form .ser-form-item .form-item-label {
  flex-shrink: 0;
  margin-right: 12px;
  text-align: right;
  color: #999999;
}

.ser-form .ser-form-item .form-item-content {
  flex: 2;
}

.ser-form .ser-form-item .form-item-content .ivu-input-wrapper {
  width: auto !important;
  margin-right: 8px;
}

.ser-page {
  padding: 12px;
  background-color: #FFFFFF;
}

.page-r {
  text-align: right;
}
</style>
